import React from 'react'
import Header from '../../Header'

function GeneralInfo() {
  return (
    <>
        <Header/>
    </>
  )
}

export default GeneralInfo